Menu = {
  addHamburgerDelegations: function () {
    $(document).on('click', '.navbar-burger', this.Navbar.toggle);
  },

  Navbar: {
    toggle: function () {
      $(this).add('.navbar-menu').toggleClass('is-active');
    }
  },

  init: function () {
    this.addHamburgerDelegations();
  }
};
