$(document).on('turbolinks:load', function () {
  $('.google-recaptcha-form').one('submit', function (e) {
    e.preventDefault();
    var $form = $(this);
    grecaptcha.ready(function() {
      grecaptcha.execute(GLOBAL_VARIABLES.recaptchaSiteKey, {action: 'submit'}).then(function(token) {
        $form.prepend('<input name="recaptcha_token" type="hidden" value="' + token + '"/>')
        $form.submit();
      });
    });
  });
});
