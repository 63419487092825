$(document).on('ajax:success', '.load-and-replace', function (event) {
  var data = event.detail[0];
  $(this).replaceWith(data.html);
});

$(document).on('ajax:error', '.load-and-replace', function (event) {
  var data = event.detail[0];
  $(this).replaceWith(data.html);
});

$(document).on('ajax:success', '.load-and-hide', function (event) {
  var data = event.detail[0];
  $(this).hide().after(data.html);
});
