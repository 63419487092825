$(document).on('ajax:success', '.reply-comment-link', function (event) {
  var data = event.detail[0];
  $(this).hide().closest('.reply-comment-wrapper').append(data.html);
});

$(document).on('ajax:success', '.edit-comment-link', function (event) {
  var data = event.detail[0];
  $(this).closest('article').hide().after(data.html);
});

$(document).on('click', '.new-comment button[name=cancel]', function () {
  $(this).closest('form').prev('a.button').show();
  $(this).closest('form').remove();
  $('.reply-comment-link').show();
});

$(document).on('click', '.edit-comment button[name=cancel]', function () {
  $(this).closest('form').prev('article').show();
  $(this).closest('form').remove();
  $('.reply-comment-link').show();
});
