// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
window.Cookies = require("js-cookie");
window.tippy = require('tippy.js').default;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("packs/global/jquery.bxslider.js");
require("packs/global/toggle-tile.js");
require("packs/global/load-ranking-account-details.js");
require("packs/global/menu.js");
Menu.init();
require("packs/global/tippy.js");
Tippy.init();
require("packs/global/bulma-message.js");
BulmaMessage.init();
require("packs/global/js-load.js.erb");
JsLoad.init();
require("packs/global/b-table-switcher.js");
require("packs/global/comparison-tool.js");
ComparisonTool.init();
require("packs/global/scroll-lock.js");
require("packs/global/remote-ujs.js");
require("packs/global/comments.js");
require("packs/global/js-link.js");
require("packs/global/conversion-tracking.js");
require("packs/global/modal.js");
require("packs/global/exit-intent-popup.js");
ExitIntentPopup.init();
require("packs/global/recaptcha.js");
import Vue from 'vue/dist/vue.esm'
import Buefy from 'buefy'
import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDayNames: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'],
  defaultMonthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
  defaultFirstDayOfWeek: 1
});
Vue.use(TurbolinksAdapter);
document.addEventListener('turbolinks:load', function () {
  if (document.getElementById('transfer-delivery')) {
    const example = {
      data() {
        return {
          showWeekNumber: false,
          formatAmPm: false,
          enableSeconds: false,
        }
      },
      computed: {
        format() {
          return this.formatAmPm ? '12' : '24'
        }
      }
    };
    const app = new Vue(example);
    app.$mount('#transfer-delivery');
  }
});
