JsLoad = {
  path: "/hd/js-load",
  addEventDelegations: function () {
    $(document).on('turbolinks:load', function () {
      $('js_load').each(JsLoad.loadAndReplace);

      $(document).one('scroll', function () {
        $('js_load_scroll').each(JsLoad.loadAndReplace);
      });
    });
  },

  loadAndReplace: function () {
    var $jsLoad = $(this);
    $.post(JsLoad.path + '/' + $jsLoad.attr('data'), function (data) {
      $jsLoad.replaceWith(data.html);
      $(document).trigger('ajax:success')
    });
  },

  init: function () {
    this.addEventDelegations();
  }
};
