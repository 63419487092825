
ExitIntentPopup = {
  lastScrollY: window.scrollY,
  lastScrollYAt: Date.now(),

  cookieName: function () {
    return 'exit-intent-closed-' + $('#exit-intent-popup').data('id');
  },

  open: function () {
    var $popup = $('#exit-intent-popup');
    if (!$popup.length) { return null; }
    $popup.addClass('is-active');
    $('html').addClass('is-clipped');
    ExitIntentPopup.setCurrentAsVisited();
  },

  isAlreadyVisited: function () {
    return Cookies.get(this.cookieName());
  },

  setCurrentAsVisited: function () {
    if (!$('#exit-intent-popup').length) { return null; }
    Cookies.set(this.cookieName(), 1, { expires: 10 });
  },

  openOnMouseOut: function () {
    $(document).on('mouseout', function (e) {
      if (!e.toElement && !e.relatedTarget && !ExitIntentPopup.isAlreadyVisited()) {
        ExitIntentPopup.open();
      }
    });
  },

  openOnMobileScrollUp: function () {
    $(document).on('scroll', function () {
      if (!ExitIntentPopup.isAlreadyVisited() && ExitIntentPopup.scrollSpeed() > 10) {
        ExitIntentPopup.open();
      }
    });
  },

  scrollSpeed: function () {
    var currentTime = Date.now(),
        currentScrollY = window.scrollY,
        elapsedTime  = this.lastScrollYAt - currentTime,
        positionChange = currentScrollY - this.lastScrollY;
    this.lastScrollY = currentScrollY;
    this.lastScrollYAt = currentTime;

    return positionChange / elapsedTime;
  },

  markVisitedOnTrackerClick: function () {
    $(document).on('click', 'a.conversion', function () {
      ExitIntentPopup.setCurrentAsVisited();
    });
  },

  addEventsDelegations: function () {
    this.markVisitedOnTrackerClick();
    if (!ExitIntentPopup.isAlreadyVisited()) {
      this.openOnMouseOut();
      if (GLOBAL_VARIABLES.isMobileDevice) {
        this.openOnMobileScrollUp();
      }
    }
  },

  init: function () {
    this.addEventsDelegations();
  }
}
