$(document).on('click', '.toggle-tile', function () {
  var $container = $(this).closest('.tile.is-ancestor'),
      $buttons = $container.find('.toggle-tile'),
      $togglable = $container.find('.togglable'),
      angle = $togglable.is(':visible') ? 90 : -90,
      startOffset, startHeight;
  if ($(this).data('scrollTop')) {
    startHeight = $togglable.height();
    startOffset = window.pageYOffset;
    $togglable.slideToggle({
      duration: 100,
      easing: 'linear',
      progress: function (animation, progress) {
        $(window).scrollTop(startOffset - progress * startHeight);
      }
    });
  } else {
    $togglable.slideToggle(100);
  }
  $buttons.find('.fa-chevron-right').animate({deg: angle}, {
    duration: 100,
    start: function (promise) {
      promise.tweens[0].start = -angle;
    },
    step: function(now, tween) {
      $(this).css({'transform': 'rotate(' + now + 'deg)'});
    },
  });
});
