Tippy = {
  initTooltips: function () {
    $(document).on('turbolinks:load ajax:success', function () {
      tippy('[data-tooltip]', {
        theme: 'light-border',
        interactive: true,
        trigger: 'mouseenter focus click',
        content(element) {
          return $(element).data('tooltip');
        }
      })
    });
  },

  init: function () {
    this.initTooltips();
  }
}
