ComparisonTool = {
  addToggleEvents: function () {
    $(document).on('click', '#comparison-drawer-toggle', ComparisonTool.toggleDrawer)
    $(document).on('click', '.comparison-drawer-toggle', function () {
      $('#comparison-drawer-toggle').click();
    });
  },

  addAjaxDelegations: function () {
    $(document).on('ajax:success', '.comparison-toggle-link', function (event) {
      var data = JSON.parse(event.detail[0]),
        comparisonDrawerIsVisible = $('#comparison-drawer').is(':visible'),
        $drawerToggle = $('#comparison-drawer-toggle');
      $('#comparison-drawer').replaceWith(data.comparisonDrawer);
      $('#comparison-drawer').toggle(comparisonDrawerIsVisible);
      $drawerToggle.toggle(comparisonDrawerIsVisible || data.accountsPresent);
      $('.comparison-toggle-link.replaceable[data-id=' + $(this).data('id') + '][data-type=' + $(this).data('type') + ']').replaceWith(data.toggleButton);
      $drawerToggle.animate({'bottom': '+=20'}, 50, function () {
        $($drawerToggle).animate({'bottom': '-=20'}, 50)
      })
    });
  },

  toggleDrawer: function () {
    var $drawer = $('#comparison-drawer'),
        $icon = $(this).find('i'),
      shouldBeVisible = $drawer.is(':hidden');
    $drawer.toggle(shouldBeVisible);
    $('html').toggleClass('scroll-lock', shouldBeVisible);
    $icon.toggleClass('fa-balance-scale-right', !shouldBeVisible)
    $icon.toggleClass('fa-times', shouldBeVisible)
  },

  init: function () {
    this.addToggleEvents();
    this.addAjaxDelegations();
  }
};
