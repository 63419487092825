BulmaMessage = {
  addEventDelegations: function () {
    $(document).on('click', '.notification > .delete, .notification > .close', function () {
      $(this).closest('.notification').fadeOut(function () {
        $(this).remove();
      });
    });
  },

  init: function () {
    this.addEventDelegations();
  }
};
